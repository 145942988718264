import { Injectable } from '@angular/core'
import { ModalService } from 'src/shared/modal/modal.service'
import {PreviewClientShippingLogComponent, PreviewClientShippingLogOptions} from './preview-client-shipping-log.component'

@Injectable()
export class PreviewClientShippingLogService {
  constructor(
    private modal: ModalService,
  ) { }

  async show(options: PreviewClientShippingLogOptions) {
    return this.modal.openDialog<PreviewClientShippingLogComponent,
        PreviewClientShippingLogOptions, void>(PreviewClientShippingLogComponent,
     options, {
       width: '100%',
       maxWidth: '100%',
    })
  }
}
