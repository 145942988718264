<div style="margin-top: 30px"  class="wht-bg-container">
    <h1><i class="fa-regular fa-circle-info"></i> Client Shipping Log Preview</h1>
    <hr style="margin-bottom: 0">
    <tc-deals-list
            [dataSource]="dataSource"
            [filtersForm]="filtersForm"
            [totals$]="totals$"
            [columnNames]="columnNames"
            [displayColumns]="displayColumns"
            [tableIdentity]="tableIdentity"
            [isEditBlocked]="true"
            [isScrollable]="true"
    ></tc-deals-list>
    <div class="modal-footer title-holder">
        <button mat-raised-button type="button"
                [color]="'primary'"
                (click)="cancel()">
            &nbsp;Ok
        </button>
    </div>
</div>
