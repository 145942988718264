import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LogisticsClientShippingLogComponent } from './client-shipping-log.component'


const routes: Routes = [{ path: '', component: LogisticsClientShippingLogComponent }]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LogisticsClientShippingLogRoutingModule { }
