import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { ModalModule } from 'src/shared/modal'
import { ToasterModule } from 'src/shared/toaster/toaster.module'
import { LogisticsClientShippingLogComponent } from './client-shipping-log.component'
import { LogisticsClientShippingLogRoutingModule } from './client-shipping-log-routing.module'
import {SelectSearchModule} from '../../../../components/select-search/select-search.module'
import {DealsListModule} from '../../../../components/deals-list'
import {DragDropModule} from '@angular/cdk/drag-drop'
import {PreviewClientShippingLogComponent} from './preview/preview-client-shipping-log.component'
import {PreviewClientShippingLogService} from './preview/preview-client-shipping-log.service'
import {StickyHeaderModule} from 'src/directives/sticky-header/sticky-header.module'
import {ContactsListModule} from './contacts-list/contacts-list.module'

@NgModule({
  declarations: [LogisticsClientShippingLogComponent, PreviewClientShippingLogComponent],
  providers: [PreviewClientShippingLogService],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    FlexLayoutModule,
    ModalModule,
    ToasterModule,
    SelectSearchModule,
    DealsListModule,
    LogisticsClientShippingLogRoutingModule,
    DragDropModule,
    StickyHeaderModule,
    ContactsListModule,
  ],
})
export class LogisticsClientShippingLogModule { }
