<div [innerHTML]="styles" style="display:none"></div>
<div class="tc-table-page-header" tc-sticky-header fxLayoutAlign="space-between center">
    <div fxFlex>
        <div class="mat-headline-6">TradeCafé · Client Shipping Log</div>
    </div>
    <button (click)="publish()" mat-raised-button [color]="'accent'">
        <i class="fa fa-paper-plane" aria-hidden="true"></i> Publish
    </button>
</div>

<div class="tc-table-page-filters wht-bg-container"
     gdColumns="repeat(1, minmax(0, 1fr))" gdGap="10px"
     gdColumns.sm="repeat(2, minmax(0, 2fr))"
     gdColumns.md="repeat(3, minmax(0, 1fr))"
     gdColumns.gt-md="repeat(4, minmax(0, 1fr))">
    <tc-select-search placeholder="Client (Buyer)"
                      [group]="filtersForm" ctrlName="buyer"
                      [items]="buyers$ | async"
                      bindValue="id"
                      bindLabel="name"
    ></tc-select-search>
    <tc-select-search placeholder="Supplier"
                      [group]="filtersForm" ctrlName="supplier"
                      [items]="suppliers$ | async"
                      bindValue="id"
                      bindLabel="name"
    ></tc-select-search>
    <tc-select-search placeholder="Service Provider"
                      [group]="filtersForm" ctrlName="carrier"
                      [items]="serviceProvider$ | async"
                      bindValue="id"
                      bindLabel="name"
    ></tc-select-search>
</div>

<div class="col-md-12 col-sm-12 col-xs-12  wht-bg-container" *ngIf="isBuyerAccount()">
    <h4>Client(Buyer) Info</h4>
    <hr>
    <div class="col-sm-12">
        <div class="row form-group">
            <div class="col-sm-3">
                <tc-select-search placeholder="Language"
                                  [ctrl]="form.controls.language"
                                  [items]="['english']"
                ></tc-select-search>
            </div>
            <div class="col-sm-9">
                <input class="form-control" value="Logistics Coordinator: {{logisticsCoordinator?.fullname}}" readonly>
            </div>
        </div>
    </div>

    <div class="col-sm-12">
        <div class="row form-group">
            <div class="col-md-3">
                <mat-form-field>
                    <mat-label>Number of days</mat-label>
                    <input matInput placeholder="Number of days" type="number"
                           [formControl]="form.controls.considered_days">
                </mat-form-field>
            </div>
            <div class="col-sm-9">
                <div class="field-help-text">
                    Number of days past delivery date for deals to be considered. This setting applies only to deals paid in
                    full.
                </div>
            </div>
        </div>
    </div>


    <div class="col-sm-12">
        <div class="row">
            <div class="form-group col-sm-12">
                <mat-checkbox
                        [formControl]="form.controls.not_report"
                >Do not report[OPEN] deals which are 30 days past shipping date. This setting applies only to [OPEN]
                    deals(i.e. deals that have not been invoiced yet)</mat-checkbox>
            </div>
        </div>
    </div>

</div>


<div *ngIf="this.account" class="col-md-12 col-sm-12 col-xs-12  wht-bg-container">
    <h4>
        Shipping Log Header
        <button (click)="openPreview()" mat-button type="button" class="btn btn-primary pull-right">
            <i class="fa fa-eye" aria-hidden="true"></i> Preview
        </button>
    </h4>
    <hr>

    <div class="form-group col-sm-2" *ngFor="let header of accountHeaders">
        <mat-checkbox
                [disabled]="header.disabled"
                [checked]="header.selected"
                (change)="changeSelectedHeader(header)"
        >
            {{header.name}}
        </mat-checkbox>
    </div>
    <div class="clearfix"></div>

    <hr>
    <div>
        <ul cdkDropList cdkDropListOrientation="horizontal" class="columns-list" (cdkDropListDropped)="dropHeader($event)">
            <div class="columns-list-item" *ngFor="let column of selectedHeaders; let i = index"
                cdkDrag
                dnd-draggable="column"
            >
                {{i + 1}} {{column.name}}
            </div>
        </ul>
    </div>
</div>


<div class="col-md-12 col-sm-12 col-xs-12  wht-bg-container" *ngIf="isBuyerAccount()">
    <h4>Copy Emails</h4>
    <hr>

    <div class="col-sm-12">
        <div class="">
            <p>Send a copy of the Client shipping log to following</p>
            <div class="form-group">
                <mat-checkbox
                        [formControl]="form.controls.email.controls.coordinator"
                > Logistics coordinator</mat-checkbox>
            </div>
            <div class="form-group">
                <mat-checkbox
                        [formControl]="form.controls.email.controls.selling_trader"
                > Selling Trader (all traders with deals in the current shipping log)</mat-checkbox>
            </div>

            <div class="form-group">
                <div
                        style="position: relative">
                    <mat-form-field style="width: 100%">
                        <mat-label>Additional email addresses (comma separated list of valid email addresses)</mat-label>
                        <input style="width: 100%" matInput placeholder="Additional email addresses (comma separated list of valid email addresses)" type="text"
                               [formControl]="form.controls.email.controls.additional">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>

<tc-contacts-list *ngIf="isBuyerAccount()" [users]="contacts" [account]="account"></tc-contacts-list>







