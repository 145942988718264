<div *ngIf="dataSource?.data" class="col-md-12 col-sm-12 col-xs-12 wht-bg-container">
    <h4>
        Contacts

        <button mat-raised-button [color]="'primary'" class="pull-right" (click)="addContact()" type="button">
            <i class="fa fa-plus"></i> Add
        </button>
    </h4>
    <hr>
    <table #contactsTable mat-table fixedLayout [dataSource]="dataSource" matSort matSortDisableClear>
        <ng-container matColumnDef="fullname">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({user: row})">
                <ng-container *ngIf="row">
                    <div class="text-ellipsis" [title]="row.fullname">
                        {{row.fullname}}
                    </div>
                </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({user: row})">
                <ng-container *ngIf="row">
                    <div class="text-ellipsis" [title]="row.role">
                        {{row.role}}
                    </div>
                </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Address</th>
            <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({user: row})">
                <ng-container *ngIf="row">
                    <div class="text-ellipsis" [title]="row.address">
                        {{row.address | address:['street1', 'city', 'country']}}
                    </div>
                </ng-container>
            </td>
        </ng-container>

        <ng-template #cellEpoch let-timestamp="timestamp" let-useUtc="useUtc">
            <div class="text-ellipsis" *ngIf="timestamp">
                <div class="text-ellipsis" [title]="timestamp | epoch:'L':useUtc">{{timestamp | epoch:'L':useUtc}}</div>
                <small class="text-ellipsis text-gray"
                       [title]="timestamp | epoch:'LT':useUtc">{{timestamp | epoch:'LT':useUtc}}</small>
            </div>
        </ng-template>

        <ng-container matColumnDef="lastupdate">
            <th mat-header-cell *matHeaderCellDef>Modification Date</th>
            <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({user: row})">
                <ng-container
                        *ngTemplateOutlet="cellEpoch; context: { timestamp: row.lastupdate, useUtc: true }"></ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th class="action-column" mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row; dataSource:dataSource">
                <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{row: row}">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayContactsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayContactsColumns"></tr>
    </table>

    <mat-menu #menu="matMenu" yPosition="below" class="nav-right-menu">
        <ng-template matMenuContent let-row="row">
            <button mat-menu-item (click)="showUpdateItem(row)">
                <i class="fa fa-edit" aria-hidden="true"></i> Edit
            </button>
            <button (click)="removeContact(row)" mat-menu-item>
                <i class="fa fa-trash-can" aria-hidden="true"></i> Delete
            </button>
        </ng-template>
    </mat-menu>
</div>
