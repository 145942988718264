import { moveItemInArray } from '@angular/cdk/drag-drop'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser'
import { select, Store } from '@ngrx/store'
import { BUYER, SERVICE_PROVIDER, SUPPLIER, TableKey, TableView, User } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed'
import { cloneDeep, map, merge, pick, remove } from 'lodash-es'
import { combineLatest } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'
import { TableViewApiService } from 'src/api/table-view'
import {
  loadAccounts, selectAccountEntities,
  selectBuyersOptions,
  selectProvidersOptions,
  selectSuppliersOptions
} from 'src/app/store/accounts'
import { loadUsers, selectUserEntities } from 'src/app/store/users'
import { TableIdentities } from 'src/constants/table-identities'
import { DealElasticSearchService } from 'src/services/data/deal-elastic.service'
import { FiltersService } from 'src/services/table-utils'
import { ModalService } from 'src/shared/modal'
import { ToasterService } from 'src/shared/toaster/toaster.service'
import { selectOptions } from 'src/shared/utils/select-options'
import { waitNotEmpty } from 'src/shared/utils/wait-not-empty'
import {
  availableServiceProviderShippingLogHeaders,
  availableShippingLogHeaders,
  availableSupplierShippingLogHeaders, columnsMap, ShippingLogHeader
} from './client-shipping-log-headers'
import { PreviewClientShippingLogService } from './preview/preview-client-shipping-log.service'
import { environment } from 'src/environments/environment'

type CustomBoolean = 0 | 1

const mapColumns = (columns, cMap) => columns.filter((i) => cMap[i]).map((i) => cMap[i] || i) || []

const tableIdentityMap = {
  [BUYER]: TableKey.ClientShippingLogList,
  [SUPPLIER]: TableKey.ClientSupplierShippingLogList,
  [SERVICE_PROVIDER]: TableKey.ClientServiceProviderShippingLogList,
}

@Component({
  selector: 'tc-logistics-client-shipping-log',
  templateUrl: './client-shipping-log.component.html',
  styleUrls: ['./client-shipping-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogisticsClientShippingLogComponent extends OnDestroyMixin implements OnInit {
  constructor(
      private store: Store,
      private fb: UntypedFormBuilder,
      private TableViewApi: TableViewApiService,
      private previewClientShippingLogService: PreviewClientShippingLogService,
      private toaster: ToasterService,
      private sanitizer: DomSanitizer,
      private chRef: ChangeDetectorRef,
      ) { super() }

  styles = this.sanitizer.bypassSecurityTrustHtml(`<style>body::-webkit-scrollbar { display: none; }</DeepReadonly<style>>`)

  displayColumns = []

  protected readonly filtersForm = this.fb.group({
    buyer: [],
    supplier: [],
    carrier: [],
  })

  form = new FormGroup({
    language: new FormControl<null | string>(null),
    considered_days: new FormControl(0),
    not_report: new FormControl<CustomBoolean>(0),
    email: new FormGroup({
      additional: new FormControl<string>(''),
      coordinator: new FormControl<CustomBoolean>(0),
      selling_trader: new FormControl<CustomBoolean>(0),
    }),
  })

  protected selectedAccountType: string
  protected account: number
  protected accountHeaders: ShippingLogHeader[] = []
  protected selectedHeaders: ShippingLogHeader[] = []
  protected logisticsCoordinator: DeepReadonly<User>
  protected tableViewId: string
  protected table: TableKey

  protected buyers$ = selectOptions(this.store, selectBuyersOptions, this.filtersForm.controls.buyer)
  protected suppliers$ = selectOptions(this.store, selectSuppliersOptions, this.filtersForm.controls.buyer)
  protected serviceProvider$ = selectOptions(this.store, selectProvidersOptions, this.filtersForm.controls.buyer)
  private accounts$ = this.store.pipe(select(selectAccountEntities), waitNotEmpty())
  private users$ = this.store.pipe(select(selectUserEntities), waitNotEmpty())
  protected contacts: DeepReadonly<User[]>

  openPreview() {
    this.previewClientShippingLogService.show({
      displayColumns: mapColumns(this.selectedHeaders.map((i) => i.id), columnsMap[this.selectedAccountType]),
      selectedAccountType: this.selectedAccountType,
      account: this.account,
      tableIdentity: tableIdentityMap[this.selectedAccountType],
    })
  }

  fetchColumns (v) {
    this.TableViewApi.list(v, {user_id: v, table: this.table}).then(({data}) => {
      let headers:  ShippingLogHeader[] = cloneDeep(this.getHeaders())
                                            .filter(col => col.id !== 'carrier_eta' || environment.enableMacropointCarrierEta) // feature flagged columns


      if (data[0]) {
        const {columns, table_view_id, attributes} = data[0] as TableView & { columns: string[] }
        this.tableViewId = table_view_id
        headers = headers.map(i => ({...i, selected: columns.indexOf(i.id) > -1}))

        if (this.selectedAccountType === BUYER) {
          if (attributes) {
            const fields = pick(attributes, ['language', 'considered_days', 'not_report', 'email'])
            const values = merge(this.form.getRawValue(), fields)

            this.form.setValue(values)
          }
        }

        this.selectedHeaders = columns.map((column) => headers.find((i) => i.id === column))
          .filter((i) => !!i)
      } else {
        this.selectedHeaders = headers.filter((i) => i.selected)
      }

        this.accountHeaders = headers
        this.account = v
        this.chRef.detectChanges()
      })
  }

  getHeaders () {
    const selectedAccount = this.selectedAccountType

    switch (selectedAccount) {
      case BUYER:
        return availableShippingLogHeaders
      case SUPPLIER:
        return availableSupplierShippingLogHeaders
      case SERVICE_PROVIDER:
        return availableServiceProviderShippingLogHeaders
      default:
        return availableShippingLogHeaders
    }
  }

  changeSelectedHeader (header)  {
    header.selected = !header.selected
    if (header.selected) {
      this.selectedHeaders.push(header)
    } else {
      remove(this.selectedHeaders, {id: header.id})
    }
  }

  dropHeader(e) {
   moveItemInArray(this.selectedHeaders, e.previousIndex, e.currentIndex)
  }

  resetPage() {
    this.accountHeaders = []
    this.selectedHeaders = []
    this.selectedAccountType = null
    this.account = null
    this.tableViewId = null
    this.form.reset()
  }


  ngOnInit(): void {
    this.store.dispatch(loadAccounts({}))
    this.store.dispatch(loadUsers({}))

    this.filtersForm.controls.supplier.valueChanges.pipe(distinctUntilChanged()).subscribe((v) => {
      this.resetPage()
      if (!v) return
      this.selectedAccountType = SUPPLIER
      this.filtersForm.controls.buyer.patchValue(null, { emitEvent: false, onlySelf: true })
      this.filtersForm.controls.carrier.patchValue(null, { emitEvent: false, onlySelf: true })
      this.table = TableIdentities.admin.supplierShippingLogList
      this.fetchColumns(v)
    })

    this.filtersForm.controls.buyer.valueChanges.pipe(distinctUntilChanged()).subscribe((v) => {
      this.resetPage()
      if (!v) return
      this.selectedAccountType = BUYER
      this.filtersForm.controls.supplier.patchValue(null, { emitEvent: false, onlySelf: true })
      this.filtersForm.controls.carrier.patchValue(null, { emitEvent: false, onlySelf: true })
      this.table = TableIdentities.admin.clientShippingLogList
      this.fetchColumns(v)
      combineLatest([this.accounts$, this.users$]).pipe(untilComponentDestroyed(this)).subscribe(([accounts, users]) => {
        this.logisticsCoordinator = users[accounts[v].coordinator]
        const userIdNumber = v * 1
        this.contacts = Object.values(users).filter((i) => i.account === userIdNumber)
      })
    })

    this.filtersForm.controls.carrier.valueChanges.pipe(distinctUntilChanged()).subscribe((v) => {
      this.resetPage()
      if (!v) return
      this.selectedAccountType = SERVICE_PROVIDER
      this.filtersForm.controls.buyer.patchValue(null, { emitEvent: false, onlySelf: true })
      this.filtersForm.controls.supplier.patchValue(null, { emitEvent: false, onlySelf: true })
      this.table = TableIdentities.admin.serviceProviderShippingLogList
      this.fetchColumns(v)
    })
    }

  publish() {
    const account = this.account
    const columns = map(this.selectedHeaders, 'id')

    const publishResult = (result) => {
      result.then((data) => {
        if (data.data) {
          this.tableViewId = data.data.table_view_id
        }
        this.toaster.success('Published successfully.')
      }, (e) => {
        this.toaster.error('Unable to publish')
      })
    }
    const v = this.form.value

    const payload: Partial<TableView> = {
      attributes: {
        not_report: v.not_report ? 1 : 0,
        language: v.language,
        considered_days: v.considered_days,
        email: {
          additional: v.email?.additional,
          coordinator: v.email?.coordinator ? 1 : 0,
          selling_trader: v.email?.selling_trader ? 1 : 0,
        },
        version: 4,
      },
      columns,
      table: this.table,
      account: undefined,
      user_id: undefined,
    }

    if (this.selectedAccountType !== BUYER) {
      delete payload.attributes
    }

    if (this.tableViewId) {
      publishResult(this.TableViewApi.update(account, this.tableViewId, payload))
      return
    }

    payload.account = account + '' // to stringconst
    payload.user_id = payload.account // account specific, use account instead of user_id

    publishResult(this.TableViewApi.create(account, payload))
  }

 isBuyerAccount ()  {
    return this.selectedAccountType === BUYER
  }
}
